@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body{background:#ececec;text-align:center;display:flex;justify-content:center;align-items:center;height:100vh;margin:0;font-family:'Roboto', sans-serif;font-weight:300}#generator{background:#fff;max-width:25em;-webkit-animation:fadeIn 1s ease-in-out;animation:fadeIn 1s ease-in-out;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transition:all 0.5s;transition:all 0.5s}@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}#title{color:#fff;font-size:1.2em;padding:1em;background:#2E87E2}#rNum{padding:0.8em;font-size:3em;margin:0;color:#212121;-webkit-transition:padding 0.2s;transition:padding 0.2s}#inputs,#headers{display:flex;align-content:center;flex-flow:row wrap;justify-content:space-around;color:#212121}#inputs input,#headers input{-webkit-appearance:none;border:0;font-size:1.1em;width:20%;border-bottom:5px solid #2E87E2;margin-bottom:2em;background:#f0f0f0;padding:0.4em;border-radius:0;-webkit-appearance:none;-webkit-transition:margin 0.2s;transition:margin 0.2s}#inputs p,#headers p{font-size:1em;color:#212121;font-weight:400}#inputs #generate,#headers #generate{webkit-appearance:none;width:100%;padding:1em 0;color:#fff;font-size:1.2em;margin:0;display:block;background:#2E87E2;cursor:pointer;-webkit-transition:background 0.3s;transition:background 0.3s;width:100%}#inputs #generate:hover,#headers #generate:hover{background:#2E87E2}@media screen and (max-height: 20em){#generator #rNum{padding:0;padding-top:0.1em}#inputs input{margin-bottom:1em}}

